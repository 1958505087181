<template>
  <div :class="['cal-wrap', { visible: visible }]" @click="handleClose">
    <div :class="['cal-container', { visible: visible }]">
      <div class="mx-auto bg-dark rounded-3 shadow-sm p-3 h-100">
        <input
          class="form-control form-control-lg"
          readonly
          v-model="calculator.displayValue"
          @click.stop=""
        />
        <!-- calculator number pad using grid -->
        <div class="row g-0 text-center mt-2 h-100">
          <div class="col-auto text-white h-100">
            <div class="row g-1 g-lg-1 h-100 pb-5">
              <div
                v-for="(key, i) in keypad"
                :key="i"
                class="ms-auto col-3 py-2"
              >
                <button
                  class="btn btn-dark w-100 cal-key"
                  @click.stop="processKey(key.value)"
                >
                  <span v-html="key.label" />
                </button>
              </div>
              <div class="col-12">
                <div
                  v-if="errValue"
                  class="alert alert-warning p-2 text-truncate small"
                  role="alert"
                >
                  {{ errValue }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculatorInput",
  props: {
    value: {
      default: 0,
    },
  },
  model: {
    prop: "value",
    event: "blur",
  },
  watch: {
    "calculator.displayValue": function (value) {
      this.$emit("blur", value);
    },
  },
  data() {
    return {
      visible: false,
      calculator: {
        displayValue: this.value,
        firstOperand: null,
        waitingForSecondOperand: false,
        operator: null,
      },
      errValue: null,
      keypad: [
        { label: "AC", value: "AC" },
        { label: `<i class="fas fa-chevron-left" />`, value: "back" },
        { label: `<i class="fas fa-chevron-right" />`, value: "next" },
        { label: `<i class="fas fa-divide" />`, value: "/" },
        { label: "7", value: 7 },
        { label: "8", value: 8 },
        { label: "9", value: 9 },
        { label: `<i class="fas fa-times" />`, value: "*" },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: `<i class="fas fa-minus" />`, value: "-" },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: `<i class="fas fa-plus" />`, value: "+" },
        { label: `<i class="fas fa-backspace" />`, value: "del" },
        { label: "0", value: 0 },
        { label: ".", value: "." },
        { label: `<i class="fas fa-equals" />`, value: "=" },
      ],
    };
  },
  methods: {
    processKey: function (val) {
      this.errValue = null;
      switch (val) {
        case "AC":
          this.resetCalculator();
          break;
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          this.inputDigit(val);
          break;
        case "+":
          this.handleOperator("+");
          break;
        case "-":
          this.handleOperator("-");
          break;
        case "/":
          this.handleOperator("/");
          break;
        case "*":
          this.handleOperator("*");
          break;
        case "=":
          this.equalPressed();
          break;
        case ".":
          this.inputDecimal(".");
          break;
        case "del":
          this.handleDel();
          break;
        case "next":
          this.$emit("next");
          setTimeout(() => {
            this.calculator.displayValue = this.value;
          }, 100);
          break;
        case "back":
          this.$emit("back");
          setTimeout(() => {
            this.calculator.displayValue = this.value;
          }, 100);
          break;
        default:
          this.errValue = "KEY ERROR: in default";
      }
    },
    equalPressed() {
      const { firstOperand, displayValue, operator } = this.calculator;
      try {
        this.calculator.displayValue = this.calculate(
          firstOperand,
          displayValue,
          operator
        );
      } catch (e) {
        this.errValue = e;
      }
    },
    inputDigit(digit) {
      const { displayValue, waitingForSecondOperand } = this.calculator;
      if (waitingForSecondOperand === true) {
        this.calculator.displayValue = digit;
        this.calculator.waitingForSecondOperand = false;
      } else {
        this.calculator.displayValue =
          displayValue === "0" ? digit : displayValue + "" + digit;
      }
    },
    inputDecimal(dot) {
      const { displayValue, waitingForSecondOperand } = this.calculator;
      if (waitingForSecondOperand === true) {
        this.calculator.displayValue = "0.";
        this.calculator.waitingForSecondOperand = false;
        return;
      }

      // check for existing decimal
      if (displayValue % 1 === 0) {
        this.calculator.displayValue += dot;
      }
    },
    handleDel() {
      const { displayValue } = this.calculator;
      if (!displayValue) return;
      this.calculator.displayValue =
        parseInt(this.calculator.displayValue.toString().slice(0, -1)) || "";
    },
    handleOperator(nextOperator) {
      const { firstOperand, displayValue, operator, waitingForSecondOperand } =
        this.calculator;
      const inputValue = parseFloat(displayValue);

      if (operator && waitingForSecondOperand) {
        this.calculator.operator = nextOperator;
        return;
      }

      if (firstOperand == null && !isNaN(inputValue)) {
        this.calculator.firstOperand = inputValue;
      } else if (operator) {
        const currentValue = firstOperand || 0;
        const result = this.calculate(currentValue, inputValue, operator);
        this.calculator.displayValue = String(result);
        this.calculator.firstOperand = result;
      }

      this.calculator.waitingForSecondOperand = true;
      this.calculator.operator = nextOperator;
    },
    calculate(firstOperand, secondOperand, operator) {
      if (operator === "+") {
        return firstOperand + secondOperand;
      } else if (operator === "-") {
        return firstOperand - secondOperand;
      } else if (operator === "*") {
        return firstOperand * secondOperand;
      } else if (operator === "/") {
        if (secondOperand == 0) {
          this.errValue = "ERROR: Cannot divide by 0";
        } else {
          return firstOperand / secondOperand;
        }
      }

      return secondOperand;
    },
    resetCalculator() {
      this.calculator.displayValue = "";
      this.calculator.firstOperand = null;
      this.calculator.waitingForSecondOperand = false;
      this.calculator.operator = null;
    },
    handleClose() {
      this.visible = false;

      setTimeout(() => {
        this.$emit("close");
      }, 100);
    },
  },
  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 100);
  },
};
</script>

<style lang="scss">
.cal {
  &-wrap {
    width: 100%;
    height: 100vh;
    max-width: 600px;
    z-index: 999;
    position: fixed;
    top: 0;
    transition: all 0.3s ease;
    background-color: transparent;

    &.visible {
      background-color: #00000099;
    }
  }

  &-container {
    position: absolute;
    bottom: 0;
    height: 60vh;
    min-height: 300px;
    transform: translateY(100%);
    transition: all 0.3s ease;

    &.visible {
      transform: translateY(0%);
    }
  }

  &-key {
    height: 100% !important;
    font-size: 24px !important;
  }
}
</style>
