<template>
  <div class="row m-0">
    <div
      :class="[
        { cheaper: isCheaper && !isCheapest },
        { cheapest: isCheapest },
        'item-group',
        'px-4',
      ]"
    >
      <div class="col-1 p-0 m-0 h5 text-left text-uppercase">
        {{ item.id }}
      </div>

      <div class="size col p-0">
        <div class="px-2">
          <input
            v-model="item.qty"
            :class="['input', { active: isQtyFocus }]"
            readonly
            placeholder="QTY"
            @click="handleClickQty"
          />
        </div>
      </div>

      <div class="col p-0">
        <div class="px-2">
          <input
            v-model="item.price"
            :class="['input', { active: isPriceFocus }]"
            readonly
            placeholder="PRICE"
            @click="handleClickPrice"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: {
    item: {
      required: true,
    },
    isCheaper: {
      default: false,
    },
    isCheapest: { default: false },
    isQtyFocus: { default: false },
    isPriceFocus: { default: false },
  },
  methods: {
    handleClickQty() {
      this.$emit("clickQty", this.item);
    },
    handleClickPrice() {
      this.$emit("clickPrice", this.item);
    },
  },
};
</script>

<style lang="scss">
.item-group {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
}

.cheapest {
  background-color: #25b2a4;
  color: #062623cc;
  input,
  select {
    color: #062623cc;
  }
}

.cheaper {
  background-color: #fff2;
}

.input {
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.1s ease-out;

  &.active {
    border: 1px solid #fff;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
}

.calc-wrapper {
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
  height: 50vh;

  border: 4px solid yellow;
}

.calculator {
  width: 100%;
  height: 100%;
  * {
    width: 100%;
    height: 100%;
  }
}
</style>
