<template>
  <div class="home">
    <item
      v-for="item in items"
      :key="item.id"
      class="item-wrap"
      :item="item"
      :isCheapest="item === cheapestItem"
      :isQtyFocus="item === selectedItem[0] && selectedItem[1] === 'qty'"
      :isPriceFocus="item === selectedItem[0] && selectedItem[1] === 'price'"
      @clickQty="onClickQty"
      @clickPrice="onClickPrice"
      :ref="`item-${item.id}`"
    />

    <div :class="['row m-0 py-3 action', { active: isCalInputOpen }]">
      <div class="col">
        <button
          v-if="items.length < 26"
          @click.prevent="addItem"
          class="btn rounded-circle"
          title="Add Item"
        >
          <i class="fas fa-plus" />
        </button>
      </div>
    </div>

    <Transition>
      <CalculatorInput
        v-if="isCalInputOpen"
        v-model="selectedItem[0][selectedItem[1]]"
        @close="onCloseCalInput"
        @next="onNext"
        @back="onBack"
      />
    </Transition>
  </div>
</template>

<script>
import Item from "@/components/Item.vue";
import CalculatorInput from "@/components/CalculatorInput.vue";

export default {
  name: "Home",
  components: { Item, CalculatorInput },
  data() {
    return {
      items:
        localStorage.getItem("items") != undefined
          ? JSON.parse(localStorage.getItem("items"))
          : [
              {
                id: "a",
                qty: "",
                price: "",
              },
              {
                id: "b",
                qty: "",
                price: "",
              },
            ],
      isCalInputOpen: false,
      selectedItem: [],
      alphabet: "abcdefghijklmnopqrstuvwxyz",
    };
  },
  watch: {
    items: {
      handler: function (items) {
        localStorage.setItem("items", JSON.stringify(items));
      },
      deep: true,
    },
  },
  computed: {
    cheapestItem() {
      return this.items.reduce(
        (cheapest, current) => {
          if (!current.price || !current.qty) return cheapest;

          return current.price / current.qty < cheapest.price / cheapest.qty ||
            (current.price / current.qty === cheapest.price / cheapest.qty &&
              current.price > cheapest.price)
            ? current
            : cheapest;
        },
        { price: Infinity, qty: 0 }
      );
    },
  },
  methods: {
    addItem() {
      this.items.push({
        id: this.alphabet[this.items.length],
        qty: "",
        price: "",
      });
    },
    onClickQty(item) {
      this.scrollToView(`item-${item.id}`);
      this.isCalInputOpen = true;
      this.selectedItem = [item, "qty"];
    },
    onClickPrice(item) {
      this.scrollToView(`item-${item.id}`);
      this.isCalInputOpen = true;
      this.selectedItem = [item, "price"];
    },
    onCloseCalInput() {
      this.isCalInputOpen = false;
      this.selectedItem = {};
    },
    onNext() {
      const [item, field] = this.selectedItem;
      const index = this.items.findIndex((i) => i === item);
      switch (field) {
        case "qty":
          this.selectedItem = [item, "price"];
          break;
        case "price":
          if (index === this.items.length - 1) break;
          this.selectedItem = [this.items[index + 1], "qty"];

          this.scrollToView(`item-${item.id}`);

          break;
        default:
          break;
      }
    },
    onBack() {
      const [item, field] = this.selectedItem;
      const index = this.items.findIndex((i) => i === item);
      switch (field) {
        case "qty":
          if (index === 0) break;
          this.selectedItem = [this.items[index - 1], "price"];

          this.scrollToView(`item-${item.id}`);
          break;
        case "price":
          this.selectedItem = [item, "qty"];
          break;
        default:
          break;
      }
    },
    scrollToView(ref) {
      setTimeout(() => {
        this.$refs[ref][0].$el.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  padding: 15px 0;
}
.btn {
  border: 1px solid #ffffff99;
  width: 40px;
  height: 40px;
  color: #ffffff99;
  &:hover {
    color: #ffffff;
  }
  padding: 0;
}

.item-wrap {
  scroll-margin-top: 84px;
}

.action {
  transition: all 0.1s ease-out;
  &.active {
    margin-top: 50vh !important;
  }
}
</style>
