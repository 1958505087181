<template>
  <div id="app">
    <div class="wrap">
      <header>
        <div class="header-child">
          <img src="@/assets/img/logo.svg" alt="Cheaper" height="36" />
          <span class="ml-2 h4 m-0">CHEAPER</span>
        </div>

        <div class="header-child">
          <button @click.prevent="clear" class="btn t-8">Clear</button>
        </div>
      </header>

      <div class="screen">
        <router-view :key="$route.fullPath" />
      </div>

      <footer>
        <div class="header-child t-5 credit">
          <span class>CHEAPER by</span>
          <a
            href="https://passawit.xyz/"
            target="_blank"
            class="btn t-8 passawit"
          >
            <img src="@/assets/img/passawit.svg" height="32" alt="Passawit" />
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clear() {
      localStorage.removeItem("items");
      this.$router.replace("/clear");
      this.$nextTick(() => this.$router.replace("/"));
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: "Heebo", sans-serif;
  // border: 1px solid yellow;
}

input,
select,
button {
  color: #fff;
  border: none;
  background-color: #fff2;
  border-radius: 5px;
  font-size: 16px !important;
  padding: 10px 10px;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

select {
  appearance: none;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.t-5 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.t-8 {
  color: rgba(255, 255, 255, 0.8) !important;
}
</style>

<style lang="scss" scoped>
#app {
  padding: 0;
  margin: 0;
  background-color: #fff;
  color: #fff;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}

header,
footer {
  height: 64px;
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  background-color: #000;
}

footer {
  justify-content: center;
}

.header-child,
.footer-child {
  height: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.wrap {
  background-color: #0f0f0f;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  height: 100%;
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.screen {
  height: calc(100% - 128px);
  width: 100%;
  position: relative;
  overflow: auto;
}

.credit {
  font-size: 14px;
}
.passawit {
  padding: 5px;
}
</style>
